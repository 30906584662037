<template>
  <dialogDiyNew
    :isShow="isShowVer"
    :dialogDiyData="uploadData"
    @dialogEvent="dialogUploadEvent"
  >
    <div slot="dialogContent" class="dia_upload_role">
      <!-- 选择组插槽 -->
      <slot name="uploadSelect"> </slot>
      <div class="dia_operas" v-show="!uploadData.selectType">
        <div class="opera_left" v-if="uploadData.labelName">
          <img src="@/assets/img/file.png" />
          <span>{{ uploadData.moduleName }}</span>
          <el-button class="mainBtn" @click="getModuleEvent"
            >点击下载</el-button
          >
        </div>
        <div class="opera_right">
          <!-- <div class="bor" @click="getLocalPic" v-if="!excelUrl">
            <img src="../../assets/img/adds.png"/>
            <span>教学计划样例</span>
          </div> -->
          <img
            class="bors"
            src="@/assets/img/uploadExcel.png"
            alt=""
            v-if="!excelUrl"
            @click="getLocalPic"
          />
          <div class="bor noBorder" @click="getLocalPic" v-else>
            {{ showFileName }}
          </div>
          <el-button class="mainBtn" @click="enterUpload">确认上传</el-button>
        </div>
      </div>
      <slot name="uploadTips"></slot>
      <input
        style="display: none"
        type="file"
        @change="picChange"
        v-if="!uploadData.noExcel"
        ref="loadPic"
        accept=" application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <input
        style="display: none"
        type="file"
        @change="picChange('zip')"
        v-if="uploadData.noExcel == 2"
        ref="loadPic"
        accept=".zip,.rar"
      />
      <input
        style="display: none"
        type="file"
        @change="picChange('allfile')"
        v-if="uploadData.noExcel == 1"
        ref="loadPic"
      />
      <input
        style="display: none"
        type="file"
        @change="picChange('pdf')"
        v-if="uploadData.noExcel == 3"
        ref="loadPic"
        accept=".pdf"
      />
    </div>
  </dialogDiyNew>
</template>

<script>
import dialogDiyNew from "./dialogDiyNew";
import { excelFileUpload, upload, zipFileUpload } from "@/api/fileUpload";
export default {
  name: "batchUpload",
  components: {
    dialogDiyNew,
  },
  props: ["isShow", "uploadData"],
  watch: {
    isShow: {
      immediate: true,
      handler(newVal, oldVal) {
        this.isShowVer = newVal;
        this.excelUrl = "";
        this.fileName = "";
        this.showFileName = "";
      },
    },
  },
  data() {
    return {
      isShowVer: false,
      excelUrl: "",
      fileName: "",
      showFileName: "",
    };
  },
  methods: {
    // 获取模板
    getModuleEvent() {
      if(this.uploadData.labelName != 'noNeed') {
        this.$request
          .getModule({ labelName: this.uploadData.labelName })
          .then((res) => {
            if (res.data.code == 0) {
              window.location.href = res.data.data;
            }
          });
      } else {
        // 学生名单下载
        // this.$request.getAuditModule(res => {
        //   if (res.code == 0) {
        //     window.location.href = res.data;
        //   }
        // });
        this.$request.getModule({ labelName: 'verifyStudentImport' }).then((res) => {
          if (res.data.code == 0) {
            window.location.href = res.data.data;
          }
        });
      }
    },
    async picChange(type) {
      let inputDOM = this.$refs.loadPic;
      let files = inputDOM.files[0];
      if (type == "allfile") {
        let obj = await upload(files);
        if (obj) {
          this.fileName = obj.fileName;
          this.showFileName = obj.showFileName;
          this.excelUrl = obj.excelUrl;
        }
      } else if(type == "zip") {
        let obj = await zipFileUpload(files);
        if (obj) {
          this.fileName = obj.fileName;
          this.showFileName = obj.showFileName;
          this.excelUrl = obj.excelUrl;
        }
      } else if(type == "pdf") {
        let obj = await this.$uploadAli.pdfFileUploadOss(files, 'pdf');
        if (obj) {
          this.fileName = obj.fileName;
          this.showFileName = obj.showFileName;
          this.excelUrl = obj.excelUrl;
        }
      } else {
        let obj = await excelFileUpload(files);
        if (obj) {
          this.fileName = obj.fileName;
          this.showFileName = obj.showFileName;
          this.excelUrl = obj.excelUrl;
        }
      }
    },
    // 确认上传
    enterUpload() {
      if (!this.excelUrl) {
        this.Warn("请选择文件");
        return;
      }
      let obj = {
        importFileUrl: this.excelUrl,
        fileName: this.fileName,
      };
      this.$emit("enterUpload", obj);
    },
    dialogUploadEvent(val) {
      this.$emit("fileUpload", val);
    },
    getLocalPic() {
      this.$refs.loadPic.value = "";
      this.$refs.loadPic.click();
    },
  },
};
</script>

<style lang="less" scoped>
.dia_upload_role {
  .dia_operas {
    display: flex;
    box-sizing: border-box;
    padding: 30px 30px 0;
    .opera_left,
    .opera_right {
      height: 180px;
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
    }
    .opera_left {
      width: 200px;
      > img {
        width: 51px;
        height: 51px;
      }
      > span {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
    // .opera_right {
    //   width: 188px;
    //   .bor {
    //     width: 188px;
    //     height: 80px;
    //     display: flex;
    //     flex-direction: column;
    //     align-items: center;
    //     justify-content: space-around;
    //     border: 2px dashed #e1e1e1;
    //     cursor: pointer;
    //     > img {
    //       width: 25px;
    //       height: 25px;
    //     }
    //     > span {
    //       font-size: 14px;
    //       font-weight: 400;
    //       color: #999999;
    //     }
    //   }
    .opera_right {
      width: 140px;
      img:hover {
        cursor: pointer;
      }
      .bors {
        height: 80px;
      }
      .bor {
        width: 140px;
        height: 80px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        border: 2px dashed #e1e1e1;
        cursor: pointer;
        > img {
          width: 25px;
          height: 25px;
        }
        > span {
          font-size: 14px;
          font-weight: 400;
          color: #999999;
        }
      }
      .noBorder {
        word-wrap: break-word;
        border: 0 none;
      }
    }
    // }
  }
  /deep/ .dia_tips {
    box-sizing: border-box;
    padding: 0 90px;
  }
  .importSolt {
    .OSBtn {
      text-align: center;
      margin: 20px 0;
    }
  }
}
/deep/ .el-scrollbar__view {
  padding: 0!important;
}
</style>
